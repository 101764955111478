import styled from "styled-components"

export const Landing = styled.div`
  background: var(--beige);
  display: block;
  position: relative;
  /* height: 70vh; */
  padding-bottom: 20rem;
  .content {
    h2 {
      margin-top: 16px;
    }
  }

  @media (min-width: 1440px) {
    .content {
      padding: 80px !important;
      padding-bottom: 13rem;
    }
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
    /* L */
    .content {
      padding: 80px 0;
      // padding-bottom: 13rem;
    }
  }

  @media (max-width: 1023px) {
    .content {
      padding: 56px 0;
      // padding-bottom: 13rem;
    }
  }
  @media (max-width: 600px) {
    /* height: 40vh; */
    height: 50vh;
    padding: 5.6rem 0;
  }
`
