import React, { useState, useEffect } from "react"
import { ContentWrapper } from "../styles/Wrapper"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import { useLanguage } from "../context/LanguageContext"
import { OutlinedButtons, Button, PrimaryButtons } from "../styles/Buttons"
import NewsSection from "../components/Homepage/NewsSection"
import { download } from "../utils/imageUpload"
import { useNav } from "../context"
import Observer from "@researchgate/react-intersection-observer"
import { useSpecialPage } from "../hooks/useSpecialPage.hook"
import { Landing } from "../styles/Landing"
import Layout from "../components/layout"

const Documents = ({ data }) => {
  const { language } = useLanguage()
  const { checkIntersection } = useNav()
  useSpecialPage(false)
  const [listLength, setlistLength] = useState(9)
  const fileArr = data.allSanityFiles.edges.map(({ node }) => node)
  const fileArrListSize = fileArr
    .filter((_, i) => i <= listLength)
    .sort((a, b) => {
      if (a.category.title < b.category.title) return -1
      else return 1
    })

  const [main] = data.allSanityTaxnews.edges.map(({ node }) => node)

  function chooseText(path, mainObj = main, lang = language) {
    return mainObj[`${path}_${lang}`]
  }

  const loadMore = action => {
    if (action === "more") {
      setlistLength(listLength + 12)
    } else {
      setlistLength(9)
    }
  }

  return (
    <Layout>
      <SEO title={chooseText("sectionTitle", main, language)} />
      <Landing style={{ paddingTop: "var(--navHeight)" }}>
        <ContentWrapper style={{ width: "100%" }}>
          <div
            className="content "
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <Observer rootMargin="-100px 0% -25%" onChange={checkIntersection}>
              <h6 className="sections">{chooseText("sectionTitle")}</h6>
            </Observer>
            <h2>{chooseText("headline")}</h2>
          </div>

          <Bar>
            <Banner>
              <NewsSection bg={"white"} fileArr={fileArr} />
            </Banner>
          </Bar>
        </ContentWrapper>
      </Landing>

      <News>
        <ContentWrapper>
          <div className="news-container ">
            <h3 className="tableTitle grey">{chooseText("titleDocuments")}</h3>
            <Grid length={fileArrListSize.length + 1}>
              <div className={`category-head-responsive heading`}>
                <h5>{chooseText("category")}</h5>
              </div>
              <div className="heading">
                <h5>{chooseText("filename")}</h5>
              </div>
              <div className="size-head-responsive heading">
                {/* <h5>{chooseText("size")}</h5> */}
              </div>
              <div className="date-head-responsive heading">
                <h5>{chooseText("date")}</h5>
              </div>
              <div className="heading">
                <h5></h5>
              </div>

              {fileArrListSize.map((file, i) => {
                return (
                  <>
                    <div
                      className={`row info category ${`category-${i +
                        1} hover-${i + 1}`}`}
                      style={{
                        color: "var(--blue)",
                        gridArea: `category-${i + 1}`
                      }}
                    >
                      {file.category.title}
                    </div>
                    <div
                      className={`row ${`name-${i + 1}`} hover-${i + 1}`}
                      style={{ gridArea: `name-${i + 1}` }}
                    >
                      <h6 className="bold ">{file.title}</h6>
                      <p
                        style={{ color: "var(--blue)" }}
                        className="category-responsive info"
                      >
                        {file.category.title}
                      </p>
                      <p className="date-responsive info">{file.date}</p>
                    </div>
                    {/* <div
                      className="row size"
                      style={{ gridArea: `size-${i + 1}` }}
                    >
                      <p className="info size">{file.size}</p>
                    </div> */}

                    <div
                      className="row date"
                      style={{ gridArea: `date-${i + 1}` }}
                    >
                      <p className="info ">{file.date}</p>
                    </div>
                    <div
                      className="row download"
                      style={{ gridArea: `download-${i + 1}` }}
                    >
                      <a href={file.file.asset.url}>
                        <img src={download} alt="" />
                      </a>
                    </div>
                  </>
                )
              })}
            </Grid>

            <div className="btn-container">
              {listLength >= fileArr.length ? (
                <Button onClick={e => loadMore("less")}>
                  {chooseText("loadLess")}
                </Button>
              ) : (
                <Button onClick={e => loadMore("more")}>
                  {chooseText("loadMore")}
                </Button>
              )}
            </div>
          </div>
        </ContentWrapper>
      </News>
    </Layout>
  )
}

export default Documents

function cleanArr(string, length, extra = 0) {
  return [
    ,
    '"',
    ...Array(length)
      .fill(string.split(" "))
      .map((el, i) => el.map(a => `${a}-${i - extra}`).join(" "))
      .join('"\n "'),
    '"'
  ].join("")
}

const Grid = styled.div`
  display: grid;
  grid-template-areas: ${props =>
    cleanArr("category name size date download", props.length)};

  grid-template-columns: repeat(5, auto);
  grid-template-rows: ${props => {
    return `repeat(${props.length}, 1fr)`
  }};
  grid-gap: 24px;

  .date-responsive,
  .category-responsive {
    display: none;
  }

  .full-row {
    :hover {
      color: green;
    }
  }
  @media (max-width: 600px) {
    grid-template-areas: ${props =>
      cleanArr("name download", props.length, -1)};

    grid-template-columns: repeat(2, auto);

    .category-responsive {
      display: block;
    }

    .category,
    .size,
    .size-head-responsive,
    .category-head-responsive,
    .heading,
    .date,
    .date-head-responsive {
      display: none;
    }

    .date-responsive {
      display: block;
    }
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    /* grid-template-columns: */
    grid-template-areas: ${props =>
      cleanArr("name category download", props.length, -1)};

    grid-template-columns: repeat(3, auto);

    .date-responsive {
      display: block;
    }
    .date,
    .size,
    .size-head-responsive,
    .heading {
      display: none;
    }
  }

  .hover-1:hover {
    // background: yellow;
  }

  @media (max-width: 600px) {
    grid-template-rows: repeat(10, 1fr);
  }
`

const News = styled.div`
  padding-top: 200px;
  background: var(--backgrounds);
  .news-container {
    padding: 80px 80px 0;
  }

  .tableTitle {
    margin-bottom: 32px;
  }

  .btn-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 14px 0 112px;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    /* L */
    padding-top: 300px;

    .news-container {
      padding: 0;
    }
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    /* M */
    padding-top: 36rem;
    .news-container {
      padding: 0;
    }
  }
  @media (max-width: 600px) {
    /* S */
    padding-top: 5.6rem;
    .news-container {
      padding: 0;
    }
  }
`

const Banner = styled.div`
  z-index: 10;
  background: white;
  border-radius: 6px;
  /* position: relative; */
  /* bottom: -15rem; */
  padding: 4rem 8rem;

  @media (max-width: 1024px) {
    padding: 4rem;
  }
  /* padding: 40px 0; */
`

const Bar = styled(ContentWrapper)`
  /* position: relative;
  bottom: -15rem; */
  bottom: -190px;
  position: absolute;
  margin-top: 6rem;
  width: 100%;
  @media (max-width: 1024px) {
    bottom: -33rem;
  }
  @media (max-width: 600px) {
    display: none;
  }
`

export const query = graphql`
  query {
    allSanityFiles(sort: { fields: date, order: DESC }) {
      totalCount
      edges {
        node {
          file {
            asset {
              url
            }
          }
          title
          size
          category {
            title
          }
          date(formatString: "MMM YY")
        }
      }
    }
    allSanityTaxnews {
      edges {
        node {
          filesNews {
            file {
              asset {
                url
              }
            }
            title
            size
            category {
              title
            }
            date(formatString: "MMM YY")
          }
          titleDocuments_de
          titleDocuments_en
          size_en
          size_de
          sectionTitle_en
          sectionTitle_de
          loadMore_en
          loadMore_de
          loadLess_en
          loadLess_de
          headline_en
          headline_de
          filename_en
          filename_de
          date_en
          date_de
          category_en
          category_de
        }
      }
    }
  }
`
